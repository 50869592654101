<template>
  <el-card class="card card--small" style="overflow: visible">
    <h1 class="card__heading">Login</h1>
    <p class="card__description">DJ & Admin panel</p>
    <el-form :model="form" label-width="50px" ref="form">
      <el-form-item>
        <template #label>
          <i class="el-icon-user-solid"></i>
        </template>
        <el-input
          v-model="form.email"
          placeholder="E-mailadres"
          autofocus
          @keyup.enter="$refs.passwordInput.focus()"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <template #label>
          <i class="el-icon-lock"></i>
        </template>
        <el-input
          ref="passwordInput"
          v-model="form.password"
          type="password"
          show-password
          placeholder="Wachtwoord"
          @keyup.enter="submitForm()"
        ></el-input>
      </el-form-item>

      <el-button
        type="primary"
        circle
        icon="el-icon-right"
        class="login-btn"
        @click="submitForm()"
      ></el-button>
    </el-form>
  </el-card>
</template>

<script>
import fetchMixins from "../mixins/ajax";
import {
  ElForm,
  ElFormItem,
  ElInput,

  //   ElPopover,
} from "element-plus";
export default {
  name: "Login",
  components: {
    ElForm,
    ElFormItem,
    ElInput,
  },

  mixins: [fetchMixins],
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // Try to log the user in
          const payload = {
            email: this.form.email,
            password: this.form.password,
          };

          const response = await this.fetchData(
            null,
            "auth/login",
            "POST",
            null,
            payload
          );

          // If error, display error message
          if (response.statusCode !== 200) {
            return this.$message({
              message: "Het inloggen is mislukt.",
              type: "error",
            });
          }

          // If success, store the token and redirect to dashboard (based on user role)
          this.$store.commit("setUserToken", response.data);
          this.$store.commit("setUserRole", response.data);

          if (response.data.token) {
            sessionStorage.setItem("token", response.data.token);
          }
          if (response.data.role) {
            sessionStorage.setItem("role", response.data.role);
          }

          const redirectPath = response.data.role || "";
          this.$router.push(`/${redirectPath}`);
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$message.closeAll();
    next();
  },
  mounted() {
    const message = this.$route.params.message;
    if (message) {
      this.$message({
        message,
        type: "warning",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.login-btn {
  display: block;
  margin-left: auto;
}
</style>
